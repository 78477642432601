<template>
<b-overlay :show="loading" :opacity="0.5">
  <section>
    <div class="d-none d-md-block mt-5">
      <referral-withdrawal-statistics :key="referralStatsRefreshKey" />
      
      <div class="mt-md-2">
        <list-table
          :records="withdrawals"
          :columns="tableColumns"
          :total-records="totalRecords"
          :show-search="false"
          :show-filter-toggle-button="false"
          :current-page-number.sync="currentPage"
        >
          <template #filters>
            <ReferralCommissionWithdrawalsListFilters 
              class="flex-grow"
              :status-filter.sync="statusFilter" 
              :can-withdraw-commission="canWithdrawCommission"
              :max-amount="available_balance"
              @withdrawal-success="refreshPageData"
            />
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ capitalizeFirstWord(data.item.status) }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              v-if="['paid', 'pending'].includes(data.item.status)"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item v-if="data.item.status === 'pending'" @click="cancelRequest(data.item)">
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Cancel Request</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.status === 'paid'" @click="() => reportAnIssue(data.item)">
                <feather-icon icon="FlagIcon" />
                <span class="align-middle ml-50">Report an issue</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </list-table>
      </div>
    </div>
  </section>
</b-overlay>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import {
  debounce,
  get
} from "lodash";
import {
  formatDate,
  formatMoney
} from "@core/utils/utils";
import ListTable from "@core/components/ListTable/ListTable.vue";
import ReferralCommissionWithdrawalsListFilters from "@/pages/client/referrals/withdrawals/ReferralCommissionWithdrawalsListFilters.vue";
import {
  BOverlay,
  BDropdown,
  BBadge,
  BButton,
  BDropdownItem,
  BDropdownGroup,
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ReferralWithdrawalStatistics from '@/pages/client/referrals/withdrawals/ReferralWithdrawalStatistics.vue';

const watchHandler = {
  handler() {
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler() {
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  name: "ListReferralCommissions",
  components: {
    ReferralCommissionWithdrawalsListFilters,
    ListTable,
    BBadge,
    BButton,
    BOverlay,
    BDropdown,
    BDropdownGroup,
    BDropdownItem,
    ReferralWithdrawalStatistics
  },
  data() {
    return {
      withdrawals: [],
      loading: false,
      statusFilter: "",
      tableColumns: [
        {
          key: 'created',
          sortable: false,
          formatter: val => `${formatDate(val)}`,
          label: 'Date',
        },
        {
          key: 'amount',
          sortable: false,
          label: 'Amount',
          formatter: val => `¢${formatMoney(val)}`
        },
        {
          key: 'status',
          sortable: false
        },
        {
          key: 'actions'
        }
      ],
      debounceFetchAllData: null,
      referralStatsRefreshKey: 0,
      available_balance: 0
    }
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value);
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get() {
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    commissionWithdrawalThreshold() {
      return this.getValueFromSource(this.settings, "loan_settings.referral_commission_withdrawal_threshold", 50)
    },
    canWithdrawCommission() {
      return this.available_balance > 0 && this.available_balance > this.commissionWithdrawalThreshold;
    }
  },
  watch: {
    perPage: watchHandlerRequiresPageReset,
    statusFilter: watchHandlerRequiresPageReset,
    currentPage: watchHandler
  },
  created() {
    this.fetchReferralCommissionsAvailableBalance();
    this.debounceFetchAllData = debounce(this.fetchReferralCommissionWithdrawals, 500);
    const { status, page = 1, limit = this.perPage } = this.$route.query;

    this.perPage = +limit;
    this.currentPage = +page;
    this.statusFilter = status;
  },
  methods: {
    async fetchReferralCommissionWithdrawals() {
      try {
        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
        };

        this.$router.push({ query }).catch(() => {});

        const response = await useJwt.clientService.fetchReferralCommissionWithdrawals(query);
        const { data, pagination } = response.data;
        this.totalRecords = pagination.totalRecords
       this.withdrawals = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    async cancelRequest(data) {
      try {
        const result = await this.$swal({
          title: "Cancel Withdrawal Request?",
          text: "You are about to cancel this withdrawal request. Continue?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.isConfirmed) {
          return;
        }

        await useJwt.clientService.cancelReferralCommissionWithdrawal(data._id);

        this.refreshPageData()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "Withdrawal request cancelled."
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    async reportAnIssue(withdrawal) {
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Issue',
          text: "Tell us about your issue.",
          input: 'textarea',
          inputPlaceholder: 'Type your message here...',
          inputAttributes: {
            'aria-label': 'Type your message here'
          },
          inputValidator: (value) => {
            return !value && 'Please enter message';
          },
          customClass: {
            title: 'text-sm',
            htmlContainer: 'mb-2',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: 'Report',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: (issue) => {
            if (!issue) return ""

            return useJwt.clientService.reportIssueWithReferralCommissionWithdrawals(withdrawal._id, { issue })
              .then(response => {
                const withdrawal_data = get(response, "data.data");
                if (!withdrawal_data){
                  throw new Error("An unexpected error occurred");
                }
                return Promise.resolve(withdrawal_data)
              })
              .catch(error => {
                const error_message = get(error, "response.data.message") || error.message;
                this.$swal.showValidationMessage(`Request failed:  ${error_message}`)
              })
          },
        });

        if (!result.value){
          return
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            text: "Issue reported successfully."
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchReferralCommissionsAvailableBalance() {
      try {
        this.loading = true;

        const response = await useJwt.clientService.fetchReferralCommissionsAvailableBalance();
        const { available_balance } = response.data.data;
        this.available_balance = available_balance;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    refreshPageData() {
      this.referralStatsRefreshKey += 1;
      this.fetchReferralCommissionWithdrawals()
      this.fetchReferralCommissionsAvailableBalance()
    }
  }
}
</script>
