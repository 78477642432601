<template>
  <b-overlay :show="loading" :opacity="0.5">
    <div
      v-if="statistics"
      class="d-md-flex pl-md-0 ml-2 justify-content-between align-items-center"
      style="gap: 5%"
    >
      <stat-card
        :stat="{
          value: getValueFromSource(statistics, 'count', 0),
          title: 'Total',
          icon: 'UsersIcon',
          iconColor: '#1ea7d3',
          backgroundColor: '#e6f5ed',
          isMoney: false,
        }"
      />
      <stat-card
        :stat="{
          value: getValueFromSource(
            statistics,
            'stats.pending',
            0
          ),
          title: 'Pending',
          icon: 'AwardIcon',
          iconColor: '#46b7fe',
          backgroundColor: '#d6fbff',
          isMoney: true,
        }"
      />
      <stat-card
        :stat="{
          value: getValueFromSource(statistics,'stats.declined',0),
          title: 'Declined',
          icon: 'AlertCircleIcon',
          iconColor: '#f91414',
          backgroundColor: '#fce9e9',
          isMoney: true,
        }"
      />
      <stat-card
        :stat="{
          value: getValueFromSource(
            statistics,
            'stats.paid',
            0
          ),
          title: 'Paid',
          icon: 'DollarSignIcon',
          iconColor: '#4fb9a4',
          backgroundColor: '#e6f5ed',
          isMoney: true,
        }"
      />
    </div>
  </b-overlay>
</template>
    
<script>
import { get } from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import StatCard from "@/pages/client/referrals/shared/StatCard.vue";
import ReferralCommissionsListFilters from "@/pages/client/referrals/list/ReferralCommissionsListFilters.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BOverlay,
  BDropdown,
  BBadge,
  BButton,
  BDropdownItem,
  BDropdownGroup,
} from "bootstrap-vue";

export default {
  name: "ReferralStatistics",
  components: {
    ReferralCommissionsListFilters,
    BBadge,
    BButton,
    BOverlay,
    BDropdown,
    BDropdownGroup,
    BDropdownItem,
    StatCard,
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      statistics: null,
    };
  },
  watch: {
    userId: {
      handler() {
        this.fetchReferralStatistics();
      },
    },
  },
  created() {
    this.fetchReferralStatistics();
  },
  methods: {
    async fetchReferralStatistics() {
      try {
        this.loading = true;

        const query = {};
        if (this.userId) {
          query.user_id = this.userId;
        }

        const response = await useJwt.clientService.fetchReferralCommissionWithdrawalStats(query);
        const { data: statistics } = response.data;
        this.statistics = statistics;
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
    
<style scoped>
</style>
