var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"opacity":0.5}},[(_vm.statistics)?_c('div',{staticClass:"d-md-flex pl-md-0 ml-2 justify-content-between align-items-center",staticStyle:{"gap":"5%"}},[_c('stat-card',{attrs:{"stat":{
        value: _vm.getValueFromSource(_vm.statistics, 'count', 0),
        title: 'Total',
        icon: 'UsersIcon',
        iconColor: '#1ea7d3',
        backgroundColor: '#e6f5ed',
        isMoney: false,
      }}}),_c('stat-card',{attrs:{"stat":{
        value: _vm.getValueFromSource(
          _vm.statistics,
          'stats.pending',
          0
        ),
        title: 'Pending',
        icon: 'AwardIcon',
        iconColor: '#46b7fe',
        backgroundColor: '#d6fbff',
        isMoney: true,
      }}}),_c('stat-card',{attrs:{"stat":{
        value: _vm.getValueFromSource(_vm.statistics,'stats.declined',0),
        title: 'Declined',
        icon: 'AlertCircleIcon',
        iconColor: '#f91414',
        backgroundColor: '#fce9e9',
        isMoney: true,
      }}}),_c('stat-card',{attrs:{"stat":{
        value: _vm.getValueFromSource(
          _vm.statistics,
          'stats.paid',
          0
        ),
        title: 'Paid',
        icon: 'DollarSignIcon',
        iconColor: '#4fb9a4',
        backgroundColor: '#e6f5ed',
        isMoney: true,
      }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }