<template>
  <section class="shadow rounded" style="min-width: 200px">
    <div class="d-flex align-items-center">
      <div 
        class="d-flex align-items-center justify-content-center rounded-circle" 
        :style="{ backgroundColor: stat.backgroundColor }"
        style="margin-left: -18%; width: 100px; height: 100px;"
      >
        <feather-icon :icon="stat.icon" size="40" :color="stat.iconColor || '#1ea7d3'" />
      </div>
      <div class="pl-md-1">
        <div class="">
          <p class="m-0">
            {{ stat.title }}
          </p>
        </div>
        <div>
          <h2 class="m-0 p-0 font-weight-bold">
            <span v-if="stat.isMoney">GH¢</span>{{ stat.isMoney ? formatMoney(stat.value) : stat.value }}
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    stat: {
      type: Object,
      default: () => ({
        title: "",
        value: "",
        icon: "",
        iconColor: "",
        backgroundColor: "#fce9e9",
        isMoney: false
      }),
      required: true,
    },
  }
};
</script>


<style lang="scss">
p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3px !important;
}
</style>
