<template>
  <b-modal
    id="withdrawal-request-modal"
    :visible="showWithdrawalRequestForm"
    centered
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:show-withdrawal-request-form', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Withdraw Referral Commission
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit, invalid }"
          ref="withdrawalRequestForm"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onSubmitWithdrawalRequest)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  <small>{{ errorMessage }}</small>
                </b-card-text>
              </div>
            </b-alert>

            <b-form-group
              :label="`Withdrawal Amount (Max: ¢${ formatMoney(maxAmount) })`"
              label-for="amount"
            >
              <cleave
                id="number"
                v-model="withdrawalPayload.amount"
                class="form-control text-lg border border-primary"
                raw
                :options="amountInputCleaveOptions"
              />
              <span v-if="withdrawalPayload.amount">
                <small v-if="withdrawalPayload.amount > maxAmount" class="text-red">
                  Amount cannot be greater than ¢{{ formatMoney(maxAmount) }}
                </small>
                <small v-else-if="withdrawalPayload.amount < minWithdrawalAmount" class="text-red">
                  Amount cannot be less than ¢{{ formatMoney(minWithdrawalAmount) }}
                </small>
              </span>
            </b-form-group>

            <b-form-group
              label="Mobile Money Number"
              label-for="mobile_money_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Money Number"
                rules="required|numeric|length:10"
                vid="Mobile Money Number"
              >
                <b-form-input
                  id="mobile_money_number"
                  v-model="withdrawalPayload.mobile_money_number"
                  :state="errors.length > 0 ? false:null"
                  name="mobile_money_number"
                  placeholder="0201234567"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="invalid || !isValidAmount"
              >
                Request Withdrawal
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton,
  BAlert, BCardText, BModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { get, cloneDeep } from 'lodash';

import { required, numeric } from '@validations'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BAlert,
    BCardText,
    BModal,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'showWithdrawalRequestForm',
    event: 'update:show-withdrawal-request-form',
  },
  props: {
    showWithdrawalRequestForm: {
      type: Boolean,
      required: true,
    },
    maxAmount: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      withdrawalPayload: cloneDeep({ amount: "", mobile_money_number: "" }),
      loading: false,
      errorMessage: "",

      required,
      numeric,

      amountInputCleaveOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        rawValueTrimPrefix: true,
        prefix: "¢ "
      },
    }
  },
  computed: {
    minWithdrawalAmount() {
      return this.getValueFromSource(this.settings, "loan_settings.referral_commission_withdrawal_threshold", 50)
    },
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    isValidAmount() {
      const { amount } = this.withdrawalPayload
      return amount <= this.maxAmount && amount >= this.minWithdrawalAmount
    }
  },
  methods: {
    async onSubmitWithdrawalRequest(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.withdrawalRequestForm.validate();
        if (!success){
          return;
        }

        const result = await this.$swal({
          title: "Confirm Withdrawal Request?",
          text: "You are about to make a withdrawal request. Continue?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.isConfirmed) {
          return;
        }

        await this.useJwt().clientService.withdrawReferralCommissions(this.withdrawalPayload);

        this.$emit('update:show-withdrawal-request-form', false);
        this.$emit('success')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Withdrawal Request Successful',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "Withdrawal request was successful."
          },
        });
      } catch (error) {
        this.errorMessage = get(error, "response.data.message") || error.message;
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.withdrawalPayload = {};
      this.errorMessage = "";
      if (this.$refs.withdrawalRequestForm) {
        this.$refs.withdrawalRequestForm.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
