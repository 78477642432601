<template>
  <div>
    <WithdrawalRequest :show-withdrawal-request-form.sync="showWithdrawalRequestForm" :max-amount="maxAmount" @success="$emit('withdrawal-success')" />
    <b-row align-v="end" align-h="between" class="w-100">
      <b-col cols="12" md="3">
        <div>
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </div>
      </b-col>
      <b-col v-if="canWithdrawCommission" cols="12" md="3" align-self="end" class="d-flex justify-content-end pr-0">
        <b-button variant="primary" @click="showWithdrawalRequestForm = true">
          Request Withdrawal
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormDatepicker,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";

import WithdrawalRequest from "../shared/WithdrawalRequest.vue"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BButton,
    WithdrawalRequest
  },
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    canWithdrawCommission: {
      type: Boolean,
      default: false,
      required: false
    },
    maxAmount: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      statusOptions: [
        { label: "Pending", value: "pending" },
        { label: "Failed", value: "failed" },
        { label: "Paid", value: "paid" },
      ],
      showWithdrawalRequestForm: false
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
